body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fr {
  float: right !important;
  text-align: right !important;
  white-space: nowrap !important;
}

.fl {
  float: left;
}

.footer {
  margin: 10px !important;
  margin-right: 30px !important;
}

.lm {
  margin-left: 30px;
}

.bm {
  margin-bottom: 100px !important;
}

.search {
  margin-left: auto !important;
  margin-right: 20px !important;
  color: #FFF !important;
}

.MuiSvgIcon-root {
  color: #4e6174 !important;
}

.cursor {
  cursor: pointer;
}

.formSubTitle {
  font-weight: 550;
  margin-bottom: 5px;
}

.match {
  color: rgb(10, 161, 30) !important;
}

.images {
  height: 75px;
}

.imagesBig {
  height: 500px;
}

.imagesPreView {
  height: 250px;
  border: 2px solid #4e6174;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  padding:10px;
}

.previewzone {
  display: -webkit-flex;
  display: flex;
}

.previewzone div {
  text-align: center;
  margin-right: 20px;
}

.dropzoneP {
  margin: 0;
  padding-top: 10px;
}

.size {
  font-weight: 100 !important;
}

.formUnit {
  display: inline-block;
  margin-left: 5px;
  height: 56px !important;
  padding-top: 30px;
}

/* MuiInputBase-colorPrimary MuiInputBase-formControl  css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root
  /*スクロールバー全体*/
::-webkit-scrollbar {
  width: 10px;
}

/*スクロールバーの軌道*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*スクロールバーの動く部分*/
::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 10px;
}